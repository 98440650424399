import React, { useState, useEffect } from "react";
import "./style.css";
import logo from "../../assets/logo.png";
import ResetPasswordModal from "../resetPassword";
import ResendEmailModal from "../resendEmail";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { FaArrowLeft } from "react-icons/fa6";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import { signout } from "../../hooks/signout.js";
import { grafanaLogout } from "../../hooks/grafanaLogout.js";
import MessagePopup from "../messagePopup";
import OrgAddModal from "../orgAdd";

function AppNavBar({ getOrgInfo, needBack }) {
  const handleLogout = async () => {
    grafanaLogout();
    await signout();
    localStorage.removeItem("token");
    localStorage.removeItem("orgId");
    localStorage.removeItem("LastSubmittedData");
    localStorage.removeItem("refresh_token");
    window.location.href = "/signin";
  };

  // message window content
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  // handle reset password modal open/close
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const handleResetPasswordModal = (status) => {
    setShowResetPasswordModal(status);
  };

  // handle resend email modal open/close
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);
  const handleResendEmailModal = (status) => {
    setShowResendEmailModal(status);
  };

  // handle add org
  const [showOrgAddModal, setShowOrgAddModal] = useState(false);
  const handleOrgAddModal = (status) => {
    setShowOrgAddModal(status);
  };

  // change the layout by page width
  const [expand, setExpand] = useState(window.innerWidth > 1000);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setExpand("sm");
      } else {
        setExpand(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="navbar-wrapper">
      <MessagePopup
        message={message}
        messageType={messageType}
        onClose={() => setMessage("")}
      />
      <Navbar key={expand} expand={expand} className="navbar-wrapper">
        <Container fluid>
          {needBack && (
            <a href="/" className="back-button">
              <FaArrowLeft /> Back
            </a>
          )}
          <Navbar.Brand href="/">
            <a href="/" className="logo">
              <img src={logo} alt="Ensaras Logo" className="logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Tools
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {localStorage.getItem("role") === "Ensaras Admin" && (
                  <button
                    className="nav-btn"
                    onClick={() => {
                      handleOrgAddModal(true);
                    }}
                  >
                    Create Org
                  </button>
                )}
                <button
                  className="nav-btn"
                  onClick={() => handleResetPasswordModal(true)}
                >
                  Reset Password
                </button>
                {(localStorage.getItem("role") === "Ensaras Admin" ||
                  localStorage.getItem("role") === "Client Admin") && (
                  <button
                    className="nav-btn"
                    onClick={() => handleResendEmailModal(true)}
                  >
                    Resend Email
                  </button>
                )}
                <button onClick={handleLogout} className="nav-btn">
                  Log out
                </button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <ResetPasswordModal
        show={showResetPasswordModal}
        onHide={() => handleResetPasswordModal(false)}
        updateMessage={updateMessage}
      />

      <ResendEmailModal
        show={showResendEmailModal}
        onHide={() => handleResendEmailModal(false)}
        updateMessage={updateMessage}
      />

      <OrgAddModal
        show={showOrgAddModal}
        onHide={() => handleOrgAddModal(false)}
        getOrgInfo={getOrgInfo}
        updateMessage={updateMessage}
      />
    </div>
  );
}

export default AppNavBar;
