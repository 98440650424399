import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import { editTeam } from '../../hooks/teamEdit.js'
import './style.css'
import moment from 'moment-timezone';

const TeamEditModal = ({ show, onHide, teamName, teamId, timezone, getOrgInfo, updateMessage }) => {
    const [newTeamName, setNewTeamName] = useState(null)
    const [newTimezone, setNewTimezone] = useState(null);

    const getTimezones = () => {
        return moment.tz.names().map((timezone) => ({
          value: timezone,
          label: timezone,
        }));
    };
    
    const timezones = getTimezones();

    useEffect(() => {
        if (teamName) {
            setNewTeamName(teamName)
        }
        if (timezone) {
            setNewTimezone(timezone)
        }
    }, [teamName])

    const handleSubmit = () => {
        editTeam({ team_id: teamId, name: newTeamName, timezone: newTimezone })
        .then((response) => {
            // Close the modal
            onHide()
            getOrgInfo()
        })
        .catch((error) => {
            updateMessage('error', `[${error.message}] Failed to edit team!`)
        })
    }

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Team</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Team Name</Form.Label>
                        <Form.Control type="text" value={newTeamName} onChange={(e) => setNewTeamName(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Timezone</Form.Label>
                        <Form.Control
                            as="select"
                            value={newTimezone}
                            onChange={(e) => setNewTimezone(e.target.value)}
                            placeholder="Select a timezone"
                            isInvalid={timezone === ""}
                            >
                            <option value="" disabled>
                                Select a timezone
                            </option>
                            {timezones.map((timezone, index) => (
                                <option key={index} value={timezone.value}>
                                {timezone.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Edit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TeamEditModal
