import React, { useState } from "react";
import {
  Modal,
  Form,
  Button,
  Badge,
  CloseButton,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { isEmail, isEmpty } from "validator";

import { resendEmail } from "../../hooks/resendEmail";
import "./style.css";

const ResendEmailModal = ({ show, onHide, updateMessage }) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [extraResponseData, setExtraResponseData] = useState({
    invalidUnregistedEmails: [],
    emailsCaughtError: [],
  });

  // sending email for reset password
  const handleResendEmail = () => {
    if (emails.length === 0) {
      setError("Please enter email address");
      return;
    }
    resendEmail({ emails: emails })
      .then((res) => {
        console.log("respomnse :: ", res);
        setMessage(res?.message);
        setExtraResponseData(res?.extra);
        setMessageType("success");
      })
      .catch((error) => {
        // updateMessage("error", `[${error.message}] Failed to resend email!`);
        setMessage(error.message);
        setMessageType("danger");
      });
  };

  // add email field
  const handleAddEmailField = () => {
    if (isEmpty(emailAddress)) {
      setError("Please enter email address");
      return;
    }
    if (!isEmail(emailAddress)) {
      setError("Invalid email address");
      return;
    }
    if (emails.includes(emailAddress)) {
      setError("Email already exists");
      return;
    }
    setEmailAddress("");
    setEmails([...emails, emailAddress]);
  };

  const handleEmailChange = (value) => {
    setError("");
    setEmailAddress(value);
  };

  // remove email field
  const handleRemoveEmailField = (email) => {
    setEmails(emails.filter((mail) => mail !== email));
  };

  const handleHide = () => {
    setEmails([]);
    setEmailAddress("");
    setError("");
    setMessage("");
    setMessageType("");
    setExtraResponseData({
      invalidUnregistedEmails: [],
      emailsCaughtError: [],
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Resend Email</Modal.Title>
      </Modal.Header>
      {message && (
        <Alert variant={messageType} className="custom-alert">
          {message}
        </Alert>
      )}
      {extraResponseData.invalidUnregistedEmails.length !== 0 && (
        <Alert variant={"danger"} className="custom-alert">
          {`Following emails are not registered: ${extraResponseData.invalidUnregistedEmails.join(
            ", "
          )}`}
        </Alert>
      )}
      {extraResponseData.emailsCaughtError.length !== 0 && (
        <Alert variant={"warning"} className="custom-alert">
          {`Email not sent to following users: ${extraResponseData.emailsCaughtError.join(
            ", "
          )}`}
        </Alert>
      )}
      <Modal.Body>
        <Form className="email-form">
          <Row className="align-items-center">
            {emails?.map((email, index) => (
              <Col xs="auto">
                <Form.Group key={index}>
                  <Form.Label>
                    <h5 className="badge-container-box">
                      <Badge
                        pill
                        variant=""
                        bg="secondary"
                        className="removable-badge"
                      >
                        {email}
                        <Button
                          variant="text"
                          size="md"
                          className="badge-close-btn"
                          onClick={() => handleRemoveEmailField(email)}
                          aria-label="Close"
                        >
                          x
                        </Button>
                      </Badge>
                      {/* <Badge bg="secondary" pill className="badge-btn">
                    {email}
                    <span className="close-btn">
                      <CloseButton
                        onClick={() => handleRemoveEmailField(email)}
                      />
                    </span>
                  </Badge> */}
                    </h5>
                  </Form.Label>
                </Form.Group>
              </Col>
            ))}
          </Row>

          <Form.Control
            type="email"
            placeholder="Enter email"
            value={emailAddress}
            onChange={(e) => handleEmailChange(e.target.value)}
            isInvalid={error}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          <Button variant="primary" onClick={handleAddEmailField}>
            Add
          </Button>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleResendEmail}>
          Resend Email
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResendEmailModal;
