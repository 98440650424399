import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { addTeam } from "../../hooks/teamAdd.js";
import "./style.css";
import moment from "moment-timezone";

const TeamAddModal = ({ show, onHide, orgId, getOrgInfo, updateMessage }) => {
  const [teamName, setTeamName] = useState(null);
  const [timezone, setTimezone] = useState("Asia/Kolkata");
  const [error, setError] = useState("");

  const getTimezones = () => {
    return moment.tz.names().map((timezone) => ({
      value: timezone,
      label: timezone,
    }));
  };

  const timezones = getTimezones();

  const handleSubmit = () => {
    if (!teamName) {
      setError("Team name is required.");
      return;
    }
    addTeam({ org_id: orgId, name: teamName, timezone: timezone })
      .then((response) => {
        // Close the modal
        handleHide();
        getOrgInfo();
      })
      .catch((error) => {
        updateMessage("error", error.message);
      });
  };

  const handleHide = () => {
    setTeamName(null);
    setTimezone("Asia/Kolkata");
    setError("");
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Team Name</Form.Label>
            <Form.Control
              isInvalid={error === "" ? false : true}
              type="text"
              placeholder="Enter Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Timezone</Form.Label>
            <Form.Control
              as="select"
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              placeholder="Select a timezone"
              isInvalid={timezone === ""}
            >
              <option value="" disabled>
                Select a timezone
              </option>
              {timezones.map((timezone, index) => (
                <option key={index} value={timezone.value}>
                  {timezone.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeamAddModal;
