import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { editData } from "../../hooks/dataEdit.js";
import "./style.css";

const DataEditModal = ({
  show,
  onHide,
  dataInfo,
  formId,
  template,
  getFormInfo,
  updateMessage,
}) => {
  const [data, setData] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [dependentFieldMap, setDependentFieldMap] = useState(null);
  const [masterFeildName, setMasterFeildName] = useState(null);
  const [errors, setErrors] = useState({});

  // useEffect(() => {
  //     if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //                 setUserPosition(`(${position.coords.latitude}, ${position.coords.longitude})`)
  //             },
  //             (error) => {
  //                 console.log("Geolocation is not available")
  //             }
  //         )
  //     }
  // }, [])

  useEffect(() => {
    template.map((field) => {
      if (field.type === "master_choice") {
        setMasterFeildName(field.name);
        setDependentFieldMap(field.dependent_values);
      }
    });
    // use copy of info
    setData({ ...dataInfo });
  }, [dataInfo, template]);

  const handleSubmit = () => {
    const newErrors = {};
    template.forEach((field) => {
      if (field.required && !data[field.name]) {
        newErrors[field.name] = `${field.name} is required`;
      }
    });

    console.log("dataInfo :: ", dataInfo);
    console.log("data :: ", data);
    let newData = { ...data };
    if (data["Shutdown Datetime"] && !data["Resolved Datetime"]) {
      newData = { ...data, "Resolved Datetime": "" };
    }
    console.log("newData :: ", newData);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    editData(data, formId, dataInfo.id)
      .then((response) => {
        // Close the modal
        onHide();
        getFormInfo();
      })
      .catch((error) => {
        updateMessage("error", `[${error.message}] Failed to edit data!`);
      });
  };

  const handleUpdate = (e, field) => {
    setErrors({
      ...errors,
      [field]: "",
    });

    setData((prevValues) => {
      // Check if the updated field is the masterField
      if (field === masterFeildName) {
        const newValues = dependentFieldMap[e.target.value];
        return {
          ...prevValues,
          ...newValues,
          [field]: e.target.value,
        };
      }
      // If not the masterField, just update the specific field
      return {
        ...prevValues,
        [field]: e.target.value,
      };
    });
  };

  const handlePosition = (field) => {
    if (userPosition) {
      setData((prevValues) => {
        return { ...prevValues, [field]: userPosition };
      });
    } else {
      console.log("Geolocation is not available");
    }
  };

  // render each field by type
  const renderFields = (field) => {
    if (data) {
      switch (field.type) {
        case "datetime":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="datetime-local"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "decimal":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "date":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="date"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "choice":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                as="select"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              >
                {field.choices.map((choice, index) => (
                  <option key={index} value={choice}>
                    {choice}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          );

        case "master_choice":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                as="select"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              >
                {field.choices.map((choice, index) => (
                  <option key={index} value={choice}>
                    {choice}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          );

        case "text":
          if (field.name === "GPS coordinates of the farm") {
            return (
              <Form.Group key={field.name} controlId={field.name}>
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Button
                  variant="outline-secondary"
                  onClick={(e) => handlePosition(field.name)}
                >
                  Get my GPS
                </Button>
              </Form.Group>
            );
          } else {
            return (
              <Form.Group key={field.name} controlId={field.name}>
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
              </Form.Group>
            );
          }

        case "text-large":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="text"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "boolean":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Check
                type="checkbox"
                value={data[field.name]}
                label={field.name}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "time":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="time"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "master_value":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        default:
          return null;
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>{template && template.map((field) => renderFields(field))}</Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataEditModal;
