import React, { useState, useEffect } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'

const MessagePopup = ({ message, messageType, onClose }) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (message) {
            setShow(true)
        }
    }, [message, messageType])

    const closePopup = () => {
        onClose()
        setShow(false)
    }
    
    return (
        <ToastContainer position={window.innerWidth > 1024 ? "top-start" : "bottom-center"}>
            <Toast onClose={() => closePopup()} show={show} delay={3000} autohide>
                <Toast.Header>
                    <strong className={`me-auto ${messageType === 'error' ? 'text-danger' : 'text-success'}`}>{messageType === 'error' ? 'Error' : 'Success'}</strong>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default MessagePopup
