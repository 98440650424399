import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { addOrg } from "../../hooks/orgAdd.js";
import "./style.css";

const OrgAddModal = ({ show, onHide, getOrgInfo, updateMessage }) => {
  const [orgName, setOrgName] = useState(null);
  const [error, setError] = useState("");

  const handleClose = () => {
    setOrgName(null);
    setError("");
    onHide();
  };

  const handleSubmit = () => {
    if (!orgName) {
      setError("Org name is required.")
      return;
    }
    addOrg({ name: orgName })
      .then((response) => {
        // Close the modal
        handleClose();
        getOrgInfo();
      })
      .catch((error) => {
        updateMessage("error", error.message);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Org</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Org Name</Form.Label>
            <Form.Control
              placeholder="Enter Org Name"
              isInvalid={error === "" ? false : true}
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrgAddModal;
